function sticky_relocate() {
    // var window_top = $(window).scrollTop();
    var payment_top = $('.yc-quick-service-form, .yc-quickbookings');

    if (payment_top.length) {
        // var payment_top = payment_top.offset().top - 75;
        // if (window_top > payment_top) {
        $('.yc-side-pan-light, .yc-price-panel').addClass('stick');
        $('footer').addClass('stick');
        // } else {
        //     $('.yc-side-pan-light, .yc-price-panel').removeClass('stick');
        //     $('footer').removeClass('stick');
        // }
    } else {
        $('footer').removeClass('stick');
    }

    // var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
    // var top_of_screen = $(window).scrollTop();

    // if ($("footer").length) {
    //     var top_of_element = $("footer").offset().top + 25;
    //     var bottom_of_element = $("footer").offset().top + $("footer").outerHeight();

    //     if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
    //         $('.yc-side-pan-light, .yc-price-panel').removeClass('stick');
    //         $('footer').removeClass('stick');
    //     }
    // }
}

$(function () {
    // $(window).scroll(sticky_relocate);
    setInterval(function () { sticky_relocate(); }, 0);
});